/*
  Emory: SMART
  Copyright (C) by Emory: SMART

  Developed by Mercury Development, LLC
  http://www.mercdev.com

*/
p {
  font-family: Arial, sans-serif;
}

a,
a:visited {
  color: blue;
}

#toolbar {
  display: flex;
  flex-wrap: wrap;
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comicSans,
.ql-font span[data-value="comicSans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courierNew,
.ql-font span[data-value="courierNew"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* Set content for sizes */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="extraSmall"]::before {
  font-size: 13px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 20px !important;
}

/* Set font-size for h1 */

.ql-editor h1 {
  font-size: 32px !important; /* default size */
}

h1 .ql-size-extraSmall {
  font-size: 22px !important;
}
h1 .ql-size-small {
  font-size: 26px !important;
}

h1 .ql-size-large {
  font-size: 36px !important;
}

/* Set font-size for h2 */

.ql-editor h2 {
  font-size: 24px !important; /* default size */
}

h2 .ql-size-extraSmall {
  font-size: 16px !important;
}
h2 .ql-size-small {
  font-size: 20px !important;
}

h2 .ql-size-large {
  font-size: 28px !important;
}

/* Set font-size for p */

.ql-editor p {
  font-size: 18px !important; /* default size */
}

p .ql-size-extraSmall {
  font-size: 13px !important;
}
p .ql-size-small {
  font-size: 14px !important;
}

p .ql-size-large {
  font-size: 20px !important;
}

/* Reset float for select */

.ql-snow .ql-picker {
  float: none;
  vertical-align: initial;
}
